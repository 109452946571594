import React from "react";
import "./App.css";
import MapComponent from "./components/map.component";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import File_upload from "./File_upload"
//import Mapcmp2 from "./components/mapcmp2"
function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MapComponent />} />
        <Route path="/captured_image" element={<File_upload />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;


